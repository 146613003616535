// components/MobileBottomNav/MobileBottomNav.tsx
import {Home, Menu, Search, ShoppingCart} from "lucide-react";
import {Button} from "../../components/ui/button";
import {Sheet, SheetContent} from "../../components/ui/sheet";
import {useShoppingCart} from "../../contexts/ListContext";
import {Badge} from "../../components/ui/badge";
import {Link} from "react-router-dom";
import React from "react";
import {ShoppingCartSheet} from "../../components/ShoppingCartSheet/ShoppingCartSheet";

interface NavItemProps {
    icon: React.ReactNode;
    label: string;
    onClick?: () => void;
    href?: string;
    badge?: number;
}

const NavItem = ({ icon, label, onClick, href, badge }: NavItemProps) => {
    const Content = (
        <div className="flex flex-col items-center">
            <div className="relative">
                {icon}
                {badge !== undefined && badge > 0 && (
                    <Badge
                        variant="destructive"
                        className="absolute -top-2 -right-2 h-4 w-4 rounded-full p-0 text-xs flex items-center justify-center"
                    >
                        {badge}
                    </Badge>
                )}
            </div>
            <span className="text-xs mt-1">{label}</span>
        </div>
    );

    if (href) {
        return (
            <Link to={href} className="flex-1">
                <Button
                    variant="ghost"
                    className="w-full h-full py-2"
                >
                    {Content}
                </Button>
            </Link>
        );
    }

    return (
        <Button
            variant="ghost"
            onClick={onClick}
            className="flex-1 h-full py-2"
        >
            {Content}
        </Button>
    );
};

interface SearchSheetProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const SearchSheet = ({ isOpen, onOpenChange }: SearchSheetProps) => (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
        <SheetContent side="bottom" className="h-[80vh]">
            <div className="h-full flex flex-col">
                <h2 className="text-lg font-semibold mb-4">Wyszukiwarka</h2>
                {/* Tu dodaj komponent wyszukiwarki */}
            </div>
        </SheetContent>
    </Sheet>
);

export const MobileBottomNav = ({
                                    onOpenMenu
                                }: {
    onOpenMenu: () => void;
}) => {
    const { cartQuantity } = useShoppingCart();
    const [isSearchOpen, setIsSearchOpen] = React.useState(false);

    return (
        <>
            <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-background border-t h-16 flex items-center px-2 z-50">
                <NavItem
                    icon={<Home className="h-5 w-5" />}
                    label="Strona główna"
                    href="/"
                />
                <NavItem
                    icon={<Search className="h-5 w-5" />}
                    label="Szukaj"
                    onClick={() => setIsSearchOpen(true)}
                />
                <ShoppingCartSheet>
                    <Button variant="ghost" className="flex-1 h-full py-2">
                        <div className="flex flex-col items-center relative">
                            <ShoppingCart className="h-5 w-5" />
                            {cartQuantity() > 0 && (
                                <Badge
                                    variant="destructive"
                                    className="absolute -top-2 -right-2 h-4 w-4 rounded-full p-0 text-xs flex items-center justify-center"
                                >
                                    {cartQuantity()}
                                </Badge>
                            )}
                            <span className="text-xs mt-1">Lista</span>
                        </div>
                    </Button>
                </ShoppingCartSheet>
                <NavItem
                    icon={<Menu className="h-5 w-5" />}
                    label="Menu"
                    onClick={onOpenMenu}
                />
            </nav>

            <SearchSheet
                isOpen={isSearchOpen}
                onOpenChange={setIsSearchOpen}
            />
        </>
    );
};