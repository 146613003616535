// MachineComponent.tsx
import React from "react";
import { useParams, Link } from "react-router-dom";
import { useMachine } from "../../hooks/useMachine";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "../../components/ui/alert";
import { AlertCircle } from "lucide-react";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import { AspectRatio } from "../../components/ui/aspect-ratio";
import { Skeleton } from "../../components/ui/skeleton";
import {ScrollArea} from "../../components/ui/scroll-area";
import {useDocumentTitle} from "../../utils/useDocumentTItle";

interface ComponentCardProps {
    imageUrl: string;
    title: string;
    href: string;
    figNumber: string;
}

const ComponentCard: React.FC<ComponentCardProps> = ({
                                                         imageUrl,
                                                         title,
                                                         href,
                                                         figNumber
                                                     }) => (
    <Card className="overflow-hidden transition-all hover:shadow-lg">
        <Link to={href}>
            <AspectRatio ratio={4/3} className="bg-muted">
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt={title}
                        className="object-cover w-full h-full"
                        loading="lazy"
                    />
                ) : (
                    <div className="flex items-center justify-center w-full h-full bg-muted">
                        <Skeleton className="w-12 h-12 rounded-full" />
                    </div>
                )}
            </AspectRatio>
            <CardContent className="p-4">
                <p className="text-sm font-medium text-primary hover:underline">
                    {title} ({figNumber})
                </p>
            </CardContent>
        </Link>
    </Card>
);

const LoadingGrid = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {[...Array(8)].map((_, i) => (
            <Card key={i} className="overflow-hidden">
                <AspectRatio ratio={4/3}>
                    <Skeleton className="w-full h-full" />
                </AspectRatio>
                <CardContent className="p-4">
                    <Skeleton className="h-4 w-[250px]" />
                </CardContent>
            </Card>
        ))}
    </div>
);

export const MachineComponent = () => {
    const { machine } = useMachine();
    const params = useParams();
    const componentId = params.componentId as string;

    const matchedFeature = machine?.features?.find(
        feature => feature.id === Number.parseInt(componentId)
    );

    // Dynamiczny tytuł strony
    const pageTitle = matchedFeature
        ? `${matchedFeature.featureName} - ${machine?.modelName} | Katalog części KUBOTA WOBIS`
        : "Komponent nie znaleziony | Katalog części KUBOTA";

    // Użycie hooka useDocumentTitle
    useDocumentTitle(pageTitle);

    // Dynamiczny opis dla meta description
    const metaDescription = matchedFeature
        ? `Przeglądaj części zamienne do komponentu ${matchedFeature.featureName} dla minikoparki ${machine?.modelName}. WOBIS Maszyny Budowlane Autoryzowany dealer części KUBOTA.`
        : "Katalog części zamiennych do minikoparek KUBOTA. Przeglądaj schematy i znajdź potrzebne części.";


    if (!matchedFeature) {
        return (
            <Alert variant="destructive" className="max-w-md mx-auto">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Błąd</AlertTitle>
                <AlertDescription>
                    Nie znaleziono wybranego komponentu.
                </AlertDescription>
            </Alert>
        );
    }

    return (
        <div className="space-y-6 pb-8 md:pb-0">
            <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Strona główna</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbLink href={`/maszyna/${machine?.id}`}>
                            {machine?.modelName}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbLink>
                            {matchedFeature.featureName}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>

            <Card>
                <CardHeader>
                    <CardTitle className="text-base md:text-2xl">
                        {machine?.modelName}: {matchedFeature.featureName}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <ScrollArea className="h-auto md:h-[calc(100svh-355px)]">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {matchedFeature.figToFeatures?.map((figToFeature, index) => (
                                <ComponentCard
                                    key={index}
                                    imageUrl={figToFeature?.illustrations?.find(illustration => illustration.url.endsWith('_1.png'))?.url || ''}
                                    title={figToFeature.name}
                                    figNumber={figToFeature.figNumber}
                                    href={`/maszyna/${machine?.id}/komponent/${matchedFeature.id}/czesci-komponentu/${figToFeature.id}`}
                                />
                            ))}
                        </div>
                    </ScrollArea>
                </CardContent>
            </Card>
        </div>
    );
};
