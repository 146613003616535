// MainSidebar.tsx
import React from 'react';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/button';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '../../components/ui/accordion';
import { Skeleton } from '../../components/ui/skeleton';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '../../components/ui/tooltip';
import {
    ChevronLeft,
    Home,
    Truck,
    Cog,
    Phone,
    ShoppingCart,
} from 'lucide-react';
import { Link, NavLink } from 'react-router-dom';
import { Machine } from '../../types/Machine';
import { useShoppingCart } from '../../contexts/ListContext';
import { ScrollArea } from "../../components/ui/scroll-area";
import {Feature} from "../../types/Feature";

interface MainSidebarProps {
    isCollapsed: boolean;
    onCollapse: () => void;
    machine: Machine | null;
    isMobile?: boolean;
    onMobileItemClick?: () => void;
}

interface SidebarLink {
    to: string;
    icon: React.ReactNode;
    label: string;
    badge?: number;
}

const NavItem = ({ link, isCollapsed }: { link: SidebarLink; isCollapsed: boolean }) => (
    <NavLink
        to={link.to}
        className={({ isActive }) => cn(
            "flex items-center space-x-2 w-full px-3 py-2 rounded-md transition-colors",
            isActive
                ? "bg-[hsl(var(--sidebar-primary))] text-[hsl(var(--sidebar-primary-foreground))]"
                : "hover:bg-[hsl(var(--sidebar-accent))]"
        )}
    >
        <Tooltip>
            <TooltipTrigger asChild>
                <div className="flex items-center w-full">
                    {link.icon}
                    {!isCollapsed && (
                        <span className="ml-2 flex-1">{link.label}</span>
                    )}
                    {!isCollapsed && link.badge && link.badge > 0 && (
                        <span className="bg-primary text-primary-foreground px-2 py-0.5 rounded-full text-xs">
                            {link.badge}
                        </span>
                    )}
                </div>
            </TooltipTrigger>
            {isCollapsed && (
                <TooltipContent side="right">
                    <div className="flex flex-col">
                        <span>{link.label}</span>
                        {link.badge && link.badge > 0 && (
                            <span className="text-xs text-muted-foreground">
                                {link.badge} elementów
                            </span>
                        )}
                    </div>
                </TooltipContent>
            )}
        </Tooltip>
    </NavLink>
);

const ComponentsList = ({
                            component,
                            machine,
                            isCollapsed
                        }: {
    component: Feature;
    machine: Machine;
    isCollapsed: boolean;
}) => {
    if (isCollapsed) {
        return (
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="relative group px-0">
                        <NavLink
                            to={`/maszyna/${machine.id}/komponent/${component.id}`}
                            className={({ isActive }) => cn(
                                "flex items-center justify-center w-8 h-8 rounded-md transition-colors mx-auto",
                                isActive
                                    ? "bg-[hsl(var(--sidebar-primary))] text-[hsl(var(--sidebar-primary-foreground))]"
                                    : "hover:bg-[hsl(var(--sidebar-accent))]"
                            )}
                        >
                            <span className="text-xs font-medium">
                                {component.featureName.charAt(0)}
                            </span>
                        </NavLink>
                        {component.figToFeatures && component.figToFeatures.length > 0 && (
                            <div className="absolute -top-1 -right-1 bg-primary text-primary-foreground rounded-full w-4 h-4 text-xs flex items-center justify-center">
                                {component.figToFeatures.length}
                            </div>
                        )}
                    </div>
                </TooltipTrigger>
                <TooltipContent side="right" className="w-64">
                    <div className="space-y-2">
                        <p className="font-medium">{component.featureName}</p>
                        <div className="space-y-1 max-h-[300px] overflow-y-auto">
                            {component.figToFeatures?.map(fig => (
                                <Link
                                    key={fig.id}
                                    to={`/maszyna/${machine.id}/komponent/${component.id}/czesci-komponentu/${fig.id}`}
                                    className="block text-xs hover:text-primary py-1 px-2 rounded-sm hover:bg-accent"
                                >
                                    <span className="font-medium">{fig.figNumber}</span> {fig.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                </TooltipContent>
            </Tooltip>
        );
    }

    return (
        <Accordion type="single" collapsible>
            <AccordionItem value="parts" className="border-none">
                <div className="flex items-center">
                    <NavLink
                        to={`/maszyna/${machine.id}/komponent/${component.id}`}
                        className={({ isActive }) => cn(
                            "flex-1 block px-2 py-1 rounded-md text-xs transition-colors",
                            isActive
                                ? "bg-[hsl(var(--sidebar-primary))] text-[hsl(var(--sidebar-primary-foreground))]"
                                : "hover:bg-[hsl(var(--sidebar-accent))]"
                        )}
                    >
                        {component.featureName}
                    </NavLink>
                    <AccordionTrigger className="px-2 hover:no-underline py-1" />
                </div>
                <AccordionContent>
                    <div className="pl-4 space-y-1">
                        {component.figToFeatures?.map(fig => (
                            <NavLink
                                key={fig.id}
                                to={`/maszyna/${machine.id}/komponent/${component.id}/czesci-komponentu/${fig.id}`}
                                className={({ isActive }) => cn(
                                    "block px-2 py-1 rounded-md text-xs transition-colors",
                                    isActive
                                        ? "bg-[hsl(var(--sidebar-primary))] text-[hsl(var(--sidebar-primary-foreground))]"
                                        : "hover:bg-[hsl(var(--sidebar-accent))]"
                                )}
                            >
                                <span className="font-medium">{fig.figNumber}</span> {fig.name}
                            </NavLink>
                        ))}
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export const MainSidebar: React.FC<MainSidebarProps> = ({
                                                            isCollapsed,
                                                            onCollapse,
                                                            machine,
                                                            isMobile = false,
                                                            onMobileItemClick
                                                        }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const { cartQuantity } = useShoppingCart();

    React.useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 1000);
        return () => clearTimeout(timer);
    }, []);

    const sidebarLinks: SidebarLink[] = [
        {
            to: "/",
            icon: <Home className="h-5 w-5" />,
            label: "Strona Główna"
        },
        {
            to: "/type/minikoparki",
            icon: <Cog className="h-5 w-5" />,
            label: "Minikoparki"
        },
        {
            to: "/type/ladowarki",
            icon: <Truck className="h-5 w-5" />,
            label: "Ładowarki Kołowe"
        },
        {
            to: "/lista",
            icon: <ShoppingCart className="h-5 w-5" />,
            label: "Lista części",
            badge: cartQuantity()
        },
        {
            to: "/kontakt",
            icon: <Phone className="h-5 w-5" />,
            label: "Kontakt"
        }
    ];

    const handleClick = () => {
        if (isMobile && onMobileItemClick) {
            onMobileItemClick();
        }
    };

    return (
        <div
            className={cn(
                "relative h-full border-r transition-all duration-300 py-4",
                "bg-[hsl(var(--sidebar-background))]",
                !isMobile && (isCollapsed ? "w-16" : "w-64"),
                isMobile && "w-full"
            )}
        >
            {!isMobile && (
                <div className="flex justify-end p-2">
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={onCollapse}
                        className="h-6 w-6"
                    >
                        <ChevronLeft className={cn(
                            "h-4 w-4 transition-transform",
                            isCollapsed && "rotate-180"
                        )} />
                    </Button>
                </div>
            )}

            <div className="space-y-2">
                <TooltipProvider delayDuration={0}>
                    <div className="px-3 space-y-1">
                        {isLoading ? (
                            Array(5).fill(0).map((_, i) => (
                                <Skeleton key={i} className="h-10 w-full" />
                            ))
                        ) : (
                            sidebarLinks.map((link) => (
                                <NavItem
                                    key={link.to}
                                    link={link}
                                    isCollapsed={isCollapsed}
                                />
                            ))
                        )}
                    </div>

                    {machine && (
                        <>
                            <div className="my-4 mx-3 border-t border-[hsl(var(--sidebar-border))]" />
                            <div className="px-3 space-y-2">
                                {isCollapsed ? (
                                    <ScrollArea className="h-[calc(100vh-440px)]">
                                        <div className="space-y-2 pr-2">
                                            {machine.features?.map(component => (
                                                <ComponentsList
                                                    key={component.id}
                                                    component={component}
                                                    machine={machine}
                                                    isCollapsed={isCollapsed}
                                                />
                                            ))}
                                        </div>
                                    </ScrollArea>
                                ) : (
                                    <Accordion type="single" collapsible value={"components"}>
                                        <AccordionItem value="components" className="border-none">
                                            <AccordionTrigger
                                                className={cn(
                                                    "px-3 py-2 hover:bg-[hsl(var(--sidebar-accent))] rounded-md",
                                                    "hover:no-underline"
                                                )}
                                            >
                                                <div className="flex items-center">
                                                    <Cog className="h-5 w-5 mr-2" />
                                                    <span>Podzespoły</span>
                                                </div>
                                            </AccordionTrigger>
                                            <AccordionContent>
                                                <ScrollArea className="h-[calc(100vh-390px)]">
                                                    <div className="mt-2 pr-2">
                                                        {machine.features?.map(component => (
                                                            <ComponentsList
                                                                key={component.id}
                                                                component={component}
                                                                machine={machine}
                                                                isCollapsed={isCollapsed}
                                                            />
                                                        ))}
                                                    </div>
                                                </ScrollArea>
                                            </AccordionContent>
                                        </AccordionItem>
                                    </Accordion>
                                )}
                            </div>
                        </>
                    )}
                </TooltipProvider>
            </div>
        </div>
    );
};