import React, {useState} from 'react';
import useFetch from '../../hooks/useFetch';
import {Machine} from '../../types/Machine';
import {PageableResponse} from '../../types/PageableResponse';
import {useParams} from 'react-router-dom';
import {useDocumentTitle} from '../../utils/useDocumentTItle';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "../../components/ui/table";
import {Skeleton} from "../../components/ui/skeleton";
import {Card, CardContent, CardHeader, CardTitle} from "../../components/ui/card";
import {Alert, AlertDescription} from "../../components/ui/alert";
import {CircleAlert} from "lucide-react";
import {
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import {columns} from "../../components/tables/columns";
import {Button} from "../../components/ui/button";
import {Input} from "../../components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/ui/select";
import {ScrollArea, ScrollBar} from "../../components/ui/scroll-area";

export const HomePage = () => {
    useDocumentTitle("WOBIS Katalog części zamiennych Kubota");

    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

    const params = useParams();
    let type;

    if (params.type !== null && params.type !== undefined) {
        type = params.type as string === 'minikoparki' ? 'MINIEXCAVATOR' : 'WHEEL_LOADER';
    }

    const url = type
        ? `${process.env.REACT_APP_API_URL}/api/v1/machines?type=${type.toUpperCase()}&sort=modelName,weight`
        : `${process.env.REACT_APP_API_URL}/api/v1/machines?sort=modelName,weight`;

    const {
        data: dataMachines,
        loading: loadingMachines,
        error: errorExcavators
    } = useFetch<PageableResponse<Machine>>(url);

    const table = useReactTable({
        data: dataMachines?.content || [],
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            columnFilters,
        },
        initialState: {
            pagination: {
                pageSize: 40, // domyślna ilość elementów na stronę
            },
        },
    })

    if (loadingMachines) {
        return (
            <Card className="w-full">
                <CardHeader>
                    <CardTitle>
                        <Skeleton className="h-8 w-3/4"/>
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-2">
                        {[...Array(5)].map((_, i) => (
                            <Skeleton key={i} className="h-12 w-full"/>
                        ))}
                    </div>
                </CardContent>
            </Card>
        );
    }

    if (errorExcavators || !dataMachines) {
        return (
            <Alert variant="destructive">
                <CircleAlert className="h-4 w-4"/>
                <AlertDescription>
                    Nie udało się załadować danych...
                </AlertDescription>
            </Alert>
        );
    }

    return (
        <div className="flex flex-col h-[calc(100vh-180px-32px)] md:h-[calc(100vh-150px-32px)]">
            {/* Tytuł i filtry - stała wysokość */}
            <div className="flex-none space-y-4 mb-4">
                <h1 className="text-base font-bold tracking-tight md:text-3xl ">
                    WOBIS Katalog części zamiennych Kubota
                </h1>
                <p className="text-sm text-muted-foreground">
                    Znaleziono: {dataMachines.totalElements} elementów
                </p>
                <Input
                    placeholder="Filtruj modele..."
                    value={(table.getColumn("modelName")?.getFilterValue() as string) ?? ""}
                    onChange={(event) =>
                        table.getColumn("modelName")?.setFilterValue(event.target.value)
                    }
                    className="max-w-sm"
                />
            </div>

            <Card className="flex-1">
                <CardContent className="p-4 h-full">
                    <div className="flex flex-col h-full">
                        {/* Container tabeli */}
                        <div className="rounded-md border flex-1 flex flex-col">
                            {/* Header tabeli - stała wysokość */}
                            <ScrollArea className="h-[calc(100svh-460px)] md:h-[calc(100svh-430px)] relative">
                                <Table>
                                    <TableHeader className="sticky top-0 bg-background z-10">
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id}>
                                                {headerGroup.headers.map((header) => (
                                                    <TableHead key={header.id}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                    </TableHead>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableHeader>
                                    <TableBody>
                                        {table.getRowModel().rows?.length ? (
                                            table.getRowModel().rows.map((row) => (
                                                <TableRow key={row.id}>
                                                    {row.getVisibleCells().map((cell) => (
                                                        <TableCell key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={columns.length}
                                                    className="h-24 text-center"
                                                >
                                                    Brak wyników.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <ScrollBar orientation="horizontal" />
                            </ScrollArea>

                            {/* Footer z paginacją - stała wysokość */}
                            <div className="flex flex-col sm:flex-row items-center md:justify-between space-y-2 sm:space-y-0 sm:space-x-2 p-4 border-t">
                                <div className="flex items-center justify-between w-full sm:w-auto sm:justify-start space-x-4">
                                    <div className="text-sm text-muted-foreground">
                                        {table.getPaginationRowModel().rows.length} z{" "}
                                        {table.getFilteredRowModel().rows.length} elementów
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <p className="text-sm font-medium">Na stronę</p>
                                        <Select
                                            value={`${table.getState().pagination.pageSize}`}
                                            onValueChange={(value) => {
                                                table.setPageSize(Number(value));
                                            }}
                                        >
                                            <SelectTrigger className="h-8 w-[70px]">
                                                <SelectValue placeholder={table.getState().pagination.pageSize}/>
                                            </SelectTrigger>
                                            <SelectContent side="top">
                                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                                    <SelectItem key={pageSize} value={`${pageSize}`}>
                                                        {pageSize}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                                <div className="flex w-full sm:w-auto space-x-2">
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => table.previousPage()}
                                        disabled={!table.getCanPreviousPage()}
                                        className="flex-1 sm:flex-none"
                                    >
                                        Poprzednia
                                    </Button>
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={() => table.nextPage()}
                                        disabled={!table.getCanNextPage()}
                                        className="flex-1 sm:flex-none"
                                    >
                                        Następna
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};