// MainLayout.tsx
import React from 'react';
import {useLocation} from 'react-router-dom';
import {useMachine} from '../../hooks/useMachine';
import {Toaster} from '../../components/ui/toaster';
import {MainSidebar} from "../../components/MainSidebar/MainSidebar";
import {MainNavbar} from "../../components/MainNavbar/MainNavbar";
import {MainFooter} from "../../components/MainFooter/MainFooter";
import {ReportBugButton} from "../../components/ReportBugForm/ReportBugForm";
import {Sheet, SheetContent} from "../../components/ui/sheet";
import {cn} from "../../lib/utils";
import {MobileBottomNav} from "../../components/MobileNavbar/MobileNavbar";

interface MainLayoutProps {
    children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const { pathname } = useLocation();
    const { machine } = useMachine();

    return (
        <div className="h-auto md:h-dvh bg-background">
            <div id="info-banner" className="text-[10px] w-full bg-orange-500 text-white text-center py-2 px-4 md:text-base">
                Prosimy o NIE WYSYŁANIE list części bezpośrednio na adresy email
            </div>

            <div className="flex h-auto md:h-[calc(100vh-40px)]">
                {/* Desktop Sidebar */}
                <div className="hidden md:block">
                    <MainSidebar
                        isCollapsed={isSidebarCollapsed}
                        onCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                        machine={machine}
                    />
                </div>

                {/* Mobile Menu */}
                <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
                    <SheetContent side="left" className="p-0 w-80">
                        <MainSidebar
                            isCollapsed={false}
                            onCollapse={() => {}}
                            machine={machine}
                            isMobile={true}
                            onMobileItemClick={() => setIsMobileMenuOpen(false)}
                        />
                    </SheetContent>
                </Sheet>

                <div className={cn(
                    "flex-1 flex flex-col overflow-hidden",
                    " md:pb-0" // Dodaj padding na dole dla mobile nav
                )}>
                    <MainNavbar />
                    <main className="flex-1 overflow-y-auto p-4 mb-16 md:mb-0">
                        {children}
                    </main>
                    <MainFooter className="hidden md:block" />
                </div>
            </div>

            {/* Mobile Bottom Navigation */}
            <MobileBottomNav onOpenMenu={() => setIsMobileMenuOpen(true)} />

            <ReportBugButton />
            <Toaster />
        </div>
    );
};