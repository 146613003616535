import { useContext } from 'react';
import MachineContext, { MachineContextType } from '../contexts/MachineContext';

export const useMachine = () => {
    const context = useContext(MachineContext);

    if (context === undefined) {
        throw new Error('useMachine must be used within a MachineProvider');
    }

    return context;
};