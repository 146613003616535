// ProceedList.tsx
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { usePost } from "../../hooks/usePost";
import { useShoppingCart } from "../../contexts/ListContext";
import { useDocumentTitle } from "../../utils/useDocumentTItle";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "../../components/ui/alert";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../components/ui/popover";
import { useToast } from "../../hooks/use-toast";
import { CheckCircle2, AlertCircle, HelpCircle, HomeIcon, Save } from "lucide-react";

// Klucz do localStorage
const FORM_STORAGE_KEY = 'kubota-form-data';

// Schema walidacji formularza
const formSchema = z.object({
    name: z.string()
        .min(1, "Imię jest wymagane")
        .regex(/^[a-zA-ZąĄęĘóÓłŁżŻźŹćĆśŚńŃ]+$/, "Imię może zawierać tylko litery (w tym polskie znaki)"),
    surname: z.string()
        .min(1, "Nazwisko jest wymagane")
        .regex(/^[a-zA-ZąĄęĘóÓłŁżŻźŹćĆśŚńŃ]+(-[a-zA-ZąĄęĘóÓłŁżŻźŹćĆśŚńŃ]+)?$/, "Nazwisko może zawierać tylko litery i opcjonalnie jeden myślnik"),
    email: z.string()
        .min(1, "Email jest wymagany")
        .email("Niepoprawny format adresu email"),
    nip: z.string()
        .min(1, "NIP jest wymagany")
        .regex(/^\d{10}$/, "NIP musi składać się z 10 cyfr"),
    phone: z.string().optional(),
    rodoAgreement: z.boolean().refine(val => val === true, "Zgoda RODO jest wymagana"),
    rodoAdmin: z.boolean().refine(val => val === true, "Zgoda administratora jest wymagana"),
    saveData: z.boolean().optional(),
});

export const ProceedList = () => {
    useDocumentTitle("Wyślij listę - Katalog Części Kubota");
    const { toast } = useToast();
    const { clearCart, cartItems } = useShoppingCart();

    // Ładowanie zapisanych danych z localStorage
    const loadSavedData = () => {
        try {
            const savedData = localStorage.getItem(FORM_STORAGE_KEY);
            if (savedData) {
                const parsedData = JSON.parse(savedData);
                // Dodajemy saveData: true aby checkbox był zaznaczony
                return { ...parsedData, saveData: true };
            }
        } catch (error) {
            console.error("Błąd podczas ładowania danych z localStorage:", error);
        }
        return {
            name: "",
            surname: "",
            email: "",
            nip: "",
            phone: "",
            rodoAgreement: false,
            rodoAdmin: false,
            saveData: false,
        };
    };

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: loadSavedData(),
    });

    // Obserwuj zmiany w formularzu i zapisuj je do localStorage jeśli zaznaczono checkbox
    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (value.saveData) {
                const dataToSave = {
                    name: value.name,
                    surname: value.surname,
                    email: value.email,
                    nip: value.nip,
                    phone: value.phone,
                    rodoAgreement: false, // Nie zapisujemy stanu checkboxów zgód
                    rodoAdmin: false,
                };
                localStorage.setItem(FORM_STORAGE_KEY, JSON.stringify(dataToSave));
            }
        });

        return () => subscription.unsubscribe();
    }, [form.watch]);

    const { data, loading, error, sendPostRequest } = usePost<any>(
        `${process.env.REACT_APP_API_URL}/api/v1/validations/list`
    );

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        const payload = {
            ...values,
            items: cartItems.map(({ id, quantity }) => ({ id, quantity })),
        };

        // Usuwamy saveData z payloadu - nie jest potrzebne na serwerze
        delete payload.saveData;

        try {
            await sendPostRequest(payload);
            clearCart();

            // Jeśli użytkownik nie chce zapisywać danych, usuwamy je
            if (!values.saveData) {
                localStorage.removeItem(FORM_STORAGE_KEY);
            }

            toast({
                title: "Lista została wysłana",
                description: "Dziękujemy za wysłanie listy. Skontaktujemy się z Tobą wkrótce.",
            });
        } catch (err: any) {
            toast({
                variant: "destructive",
                title: "Błąd podczas wysyłania",
                description: err.response?.data?.message || "Wystąpił błąd podczas wysyłania listy",
            });
        }
    };

    if (cartItems.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center space-y-4 min-h-[400px]">
                <Alert variant="destructive" className="max-w-md">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Lista jest pusta</AlertTitle>
                    <AlertDescription>
                        Niestety w liście nie znajdują się żadne części.
                        Wróć na stronę główną i wybierz interesującą Cię maszynę.
                    </AlertDescription>
                </Alert>
                <Button asChild variant="default">
                    <Link to="/" className="flex items-center space-x-2">
                        <HomeIcon className="h-4 w-4" />
                        <span>Wróć na stronę główną</span>
                    </Link>
                </Button>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-[400px]">
                <div className="space-y-4 text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto" />
                    <p className="text-muted-foreground">Wysyłanie wiadomości...</p>
                </div>
            </div>
        );
    }

    return (
        <Card className="max-w-2xl mx-auto">
            <CardHeader>
                <CardTitle>Wyślij listę</CardTitle>
                <CardDescription>
                    Wypełnij formularz, aby otrzymać wycenę wybranych części
                </CardDescription>
            </CardHeader>
            <CardContent>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                        <div className="grid grid-cols-2 gap-4">
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Imię</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Jan" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="surname"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nazwisko</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Kowalski" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormField
                            control={form.control}
                            name="nip"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>NIP</FormLabel>
                                    <FormControl>
                                        <Input placeholder="1234567890" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="jan.kowalski@firma.pl" type="email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="phone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Telefon (opcjonalnie)</FormLabel>
                                    <FormControl>
                                        <Input placeholder="+48 123 456 789" type="tel" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        {/* Checkbox zapisywania danych */}
                        <FormField
                            control={form.control}
                            name="saveData"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 p-3 rounded-md border border-dashed border-muted-foreground/50">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <div className="space-y-1 leading-none">
                                        <FormLabel className="flex items-center font-medium">
                                            <Save className="h-4 w-4 mr-2" />
                                            Zapamiętaj moje dane na tym urządzeniu
                                        </FormLabel>
                                        <p className="text-xs text-muted-foreground">
                                            Twoje dane zostaną zapisane lokalnie na tym urządzeniu,
                                            aby ułatwić wypełnianie formularza w przyszłości
                                        </p>
                                    </div>
                                </FormItem>
                            )}
                        />

                        <div className="space-y-4 p-4 rounded-md bg-muted">
                            <h3 className="font-medium text-sm">Wymagane zgody</h3>

                            <FormField
                                control={form.control}
                                name="rodoAgreement"
                                render={({ field }) => (
                                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                        <FormControl>
                                            <Checkbox
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        </FormControl>
                                        <div className="space-y-1 leading-none">
                                            <FormLabel>
                                                Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przygotowania
                                                wyceny części oraz kontaktu w sprawie mojego zapytania
                                            </FormLabel>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="rodoAdmin"
                                render={({ field }) => (
                                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                        <FormControl>
                                            <Checkbox
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        </FormControl>
                                        <div className="space-y-1 leading-none">
                                            <div className="flex items-center space-x-2">
                                                <FormLabel>
                                                    Potwierdzam, że zapoznałem się z informacją o administratorze danych
                                                </FormLabel>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <Button variant="ghost" size="icon" className="h-5 w-5">
                                                            <HelpCircle className="h-4 w-4" />
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-80 text-xs">
                                                        Administratorem danych osobowych jest WOBIS Maszyny Budowlane Wojtyczok Sp. K, NIP 648 278 12 74 (dalej: „Administrator”).
                                                        Pana/Pani dane będą przetwarzane w celu prowadzenia korespondencji oraz w celach handlowych i
                                                        marketingowych wynikających z treści przesłanego zapytania.
                                                        Dane zostaną usunięte po odwołaniu zgody lub po zakończeniu prowadzenia działań marketingowych
                                                        lub wysyłki informacji handlowych przez Administratora.
                                                        Pana/Pani dane będą powierzane podmiotom trzecim na podstawie stosownych umów powierzenia przetwarzania
                                                        danych osobowych w celu przechowywania danych osobowych na serwerze, skrzynce pocztowej oraz korzystania z usług wsparcia IT.
                                                        Podstawą przetwarzania danych jest zgoda. W związku z przetwarzaniem danych osobowych ma Pan/Pani prawo do dostępu do swoich danych,
                                                        sprostowania danych osobowych, usunięcia danych osobowych, wniesienia sprzeciwu, przenoszenia danych, ograniczenia przetwarzania,
                                                        odwołania zgody, dostępu do informacji jakie dane Administrator przetwarza, złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
                                                        Przedmiotowe uprawnienia można zrealizować poprzez kontakt z Administratorem na adres e-mail: krzysztofokula@wobis.pl.
                                                        Pani/Pana dane nie będą przekazywane poza teren UE oraz nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji ani profilowania.
                                                        Administrator potrzebuje Pana/Pani Danych Osobowych aby zrealizować wskazany cel przetwarzania, podanie danych osobowych jest dobrowolne.
                                                        W przypadku odwołania zgody, cele nie będą mogły być zrealizowane.
                                                    </PopoverContent>
                                                </Popover>
                                            </div>
                                            <FormMessage />
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <Button
                            type="submit"
                            className="w-full"
                            disabled={loading}
                        >
                            {loading ? "Wysyłanie listy..." : "Wysyłam darmową listę"}
                        </Button>
                    </form>
                </Form>
            </CardContent>
        </Card>
    );
};