import React, { createContext, useContext, useEffect } from "react";
import { PartToFig } from "../types/PartToFig";
import * as XLSX from 'xlsx';
import { useToast } from "../hooks/use-toast";

type CartItem = {
    id: number;
    partNumber: string;
    name: string;
    quantity: number;
}

type ListContext = {
    openCart: () => void;
    closeCart: () => void;
    getItemQuantity: (id: number) => number;
    addItem: (part: PartToFig) => void;
    removeItem: (id: number) => void;
    updateQuantity: (id: number, quantity: number) => void;
    cartQuantity: () => number;
    handleExport: (format: 'csv' | 'txt' | 'xlsx') => void;
    cartItems: CartItem[];
    clearCart: () => void;
}

const ListContext = createContext({} as ListContext);

export function useShoppingCart() {
    return useContext(ListContext);
}

export const ListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [cartItems, setCartItems] = React.useState<CartItem[]>(() => {
        const savedCartItems = localStorage.getItem('cartItems');
        return savedCartItems ? JSON.parse(savedCartItems) : [];
    });

    const { toast } = useToast();

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const [isCartOpen, setIsCartOpen] = React.useState(false)

    const addItem = (part: PartToFig) => {
        setCartItems(currentItems => {
            const existingItem = currentItems.find(item => item.id === part.id);

            if (existingItem) {
                toast({
                    title: "Zaktualizowano ilość",
                    description: `${part.name} - nowa ilość: ${existingItem.quantity + part.quantity}`,
                });

                return currentItems.map(item => {
                    if (item.id === part.id) {
                        return { ...item, quantity: item.quantity + part.quantity };
                    }
                    return item;
                });
            }

            toast({
                title: "Dodano do listy",
                description: `${part.name} - ilość: ${part.quantity}`,
            });

            return [...currentItems, {
                id: part.id,
                partNumber: part.wobisPartNumber,
                name: part.name,
                quantity: part.quantity
            }];
        });
    };

    const removeItem = (id: number) => {
        setCartItems(currentItems => {
            const itemToRemove = currentItems.find(item => item.id === id);
            if (itemToRemove) {
                toast({
                    title: "Usunięto z listy",
                    description: itemToRemove.name,
                    variant: "destructive",
                });
            }
            return currentItems.filter(item => item.id !== id);
        });
    };

    // Rest of your existing ListContext implementation...
    const openCart = () => {
        setIsCartOpen(true);
    }

    const closeCart = () => {
        setIsCartOpen(false);
    }

    const updateQuantity = (id: number, quantity: number) => {
        setCartItems(cartItems.map(item => {
            if (quantity < 0) {
                quantity = 0;
            }
            if (item.id === id) {
                return {
                    ...item,
                    quantity: quantity
                }
            } else {
                return item
            }
        }))
    }

    const getItemQuantity = (id: number) => {
        const item = cartItems.find(item => item.id === id)
        return item ? item.quantity : 0
    }

    const cartQuantity = () => {
        return cartItems.reduce((total, item) => total + item.quantity, 0);
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const exportToFile = (filename: string, data: string) => {
        const blob = new Blob([data], { type: 'text/plain; charset=utf-8' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const formatDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}-${hours}${minutes}`;
    };

    const exportToCSVOrTXT = (format: 'csv' | 'txt') => {
        const data = cartItems.map(item => `${item.partNumber}; ${item.name}; ${item.quantity}`).join('\n');
        const content = `Numer; Nazwa; Ilość\n${data}\nKontakt:\nE-mail ogólny: zamowienia@wobis.pl\nAdam Żymła: +48 609 893 929 adamzymla@wobis.pl\nDamian Kwiotek: +48 693 325 371 damiankwiotek@wobis.pl`;
        exportToFile(`wobis-lista-eksport-${formatDate()}.${format}`, content);
    };

    const exportToXLSX = () => {
        const ws = XLSX.utils.aoa_to_sheet([['Numer', 'Nazwa', 'Ilość']]);
        cartItems.forEach((item, index) => {
            XLSX.utils.sheet_add_aoa(ws, [[item.partNumber, item.name, item.quantity]], { origin: index + 1 });
        });
        XLSX.utils.sheet_add_aoa(ws, [['Kontakt:', ''], ['E-mail ogólny:', 'zamowienia@wobis.pl'], ['Adam Żymła:', '+48 609 893 929'], ['Damian Kwiotek:', '+48 693 325 371']], { origin: cartItems.length + 2 });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Części");
        XLSX.writeFile(wb, `wobis-lista-eksport-${formatDate()}.xlsx`);
    };

    const handleExport = (format: 'csv' | 'txt' | 'xlsx') => {
        if (format === 'xlsx') {
            exportToXLSX();
        } else {
            exportToCSVOrTXT(format);
        }
    };

    return (
        <ListContext.Provider value={{
            openCart,
            closeCart,
            getItemQuantity,
            addItem,
            removeItem,
            updateQuantity,
            cartQuantity,
            cartItems,
            clearCart,
            handleExport
        }}>
            {children}
        </ListContext.Provider>
    );
};