// Contact.tsx
import { useDocumentTitle } from '../../utils/useDocumentTItle';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle
} from '../../components/ui/card';
import { PhoneCall, Mail, MapPin } from 'lucide-react';

interface ContactCardProps {
    icon: React.ReactNode;
    title: string;
    children: React.ReactNode;
}

const ContactCard = ({ icon, title, children }: ContactCardProps) => (
    <Card className="flex flex-col items-center text-center">
        <CardHeader className="flex flex-col items-center">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-primary/10 mb-2">
                {icon}
            </div>
            <CardTitle className="text-xl font-semibold text-center">{title}</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col items-center space-y-2">
            {children}
        </CardContent>
    </Card>
);

const ContactLink = ({ href, children }: { href: string; children: React.ReactNode }) => (
    <a
        href={href}
        className="text-primary hover:text-primary/80 transition-colors"
    >
        {children}
    </a>
);

export const Contact = () => {
    useDocumentTitle("Kontakt - Katalog części KUBOTA");

    const phoneData = [
        { name: "Adam Żymła", number: "+48 609 893 929" },
        { name: "Damian Kwiotek", number: "+48 693 325 371" }
    ];

    const emailData = [
        { email: "zamowienia@wobis.pl" },
        { email: "adamzymla@wobis.pl" },
        { email: "damiankwiotek@wobis.pl" }
    ];

    const addressData = {
        street: "ul. Bytomska 135",
        city: "41-803, Zabrze"
    };

    return (
        <div className="container mx-auto py-16`">
            <h1 className="text-3xl font-bold mb-8 text-center">Kontakt</h1>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Telefon */}
                <ContactCard
                    icon={<PhoneCall className="h-6 w-6 text-primary" />}
                    title="Telefon"
                >
                    {phoneData.map(({ name, number }, index) => (
                        <div key={index} className="text-sm">
                            {name}: <ContactLink href={`tel:${number.replace(/\s/g, '')}`}>
                            {number}
                        </ContactLink>
                        </div>
                    ))}
                </ContactCard>

                {/* Email */}
                <ContactCard
                    icon={<Mail className="h-6 w-6 text-primary" />}
                    title="E-Mail"
                >
                    {emailData.map(({ email }, index) => (
                        <ContactLink key={index} href={`mailto:${email}`}>
                            {email}
                        </ContactLink>
                    ))}
                </ContactCard>

                {/* Lokalizacja */}
                <ContactCard
                    icon={<MapPin className="h-6 w-6 text-primary" />}
                    title="Lokalizacja"
                >
                    <address className="not-italic text-sm">
                        {addressData.street}<br />
                        {addressData.city}
                    </address>
                </ContactCard>
            </div>
        </div>
    );
};
