import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../components/theme-provider';
import { ThemeToggle } from '../../components/theme-toggle';
import { ShoppingCartSheet } from '../../components/ShoppingCartSheet/ShoppingCartSheet';
// @ts-ignore
import logo from "../../assets/wobis_male_2021-300x64.webp";
// @ts-ignore
import logoDark from "../../assets/wobis-kubota-logo-biale.webp";
import {SearchBar} from "../../components/SearchBar/SearchBar";

export const MainNavbar: React.FC = () => {
    const { theme } = useTheme();

    return (
        <nav className="border-b border-border bg-background px-4 py-3">
            <div className="flex items-center justify-between">
                <Link to="/" className="flex items-center space-x-2">
                    <img
                        src={theme === 'dark' ? logoDark : logo}
                        alt="Wobis Logo"
                        className="max-w-[120px] h-auto md:max-w-none md:h-8 w-auto"
                    />
                    <span className="text-xs font-semibold md:text-sm max-w-[200px] leading-tight">
                        Autoryzowany Dealer minikoparek i części KUBOTA
                    </span>
                </Link>

                {/* Wyszukiwarka - widoczna tylko na desktopach */}
                <SearchBar className="hidden md:flex max-w-md mx-4" />

                <div className="flex items-center space-x-4">
                    <ThemeToggle />
                    <ShoppingCartSheet className="hidden md:block" />
                </div>
            </div>
        </nav>
    );
};