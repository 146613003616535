// SearchBar.tsx
import React, { useState } from 'react';
import { Input } from '../ui/input';
import { Search } from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '../ui/select';

interface SearchBarProps {
    className?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({ className }) => {
    const [searchCategory, setSearchCategory] = useState("wszystko");

    return (
        <div className={`flex items-center space-x-2 w-full ${className}`}>
            <div className="flex w-full">
                <Select
                    defaultValue="wszystko"
                    onValueChange={setSearchCategory}
                >
                    <SelectTrigger className="w-[140px] rounded-r-none border-r-0">
                        <SelectValue placeholder="Kategoria" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="wszystko">Wszystko</SelectItem>
                        <SelectItem value="komponent">Komponent</SelectItem>
                        <SelectItem value="część">Część</SelectItem>
                        <SelectItem value="podzespół">Podzespół</SelectItem>
                    </SelectContent>
                </Select>
                <div className="relative flex-1">
                    <Input
                        type="search"
                        placeholder="Szukaj..."
                        className="w-full rounded-l-none pl-3 pr-10"
                    />
                    <button className="absolute right-3 top-1/2 transform -translate-y-1/2">
                        <Search className="h-4 w-4 text-muted-foreground" />
                    </button>
                </div>
            </div>
        </div>
    );
};