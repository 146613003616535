import React from 'react';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import { X } from "lucide-react";
import { useShoppingCart } from "../../contexts/ListContext";

interface ClearCartDialogProps {
    variant?: "default" | "destructive" | "outline" | "secondary" | "ghost" | "link";
    size?: "default" | "sm" | "lg" | "icon";
    className?: string;
}

export const ClearCartDialog: React.FC<ClearCartDialogProps> = ({
                                                                    variant = "destructive",
                                                                    size = "sm",
                                                                    className
                                                                }) => {
    const { clearCart } = useShoppingCart();
    const [open, setOpen] = React.useState(false);

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
                <Button
                    variant={variant}
                    size={size}
                    className={className}
                >
                    <X className="h-4 w-4 mr-1" />
                    Wyczyść listę
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent
                onEscapeKeyDown={() => setOpen(false)}
            >
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        Czy na pewno chcesz wyczyścić listę?
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                        Ta akcja usunie wszystkie części z Twojej listy.
                        Tej operacji nie można cofnąć.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={() => setOpen(false)}>
                        Anuluj
                    </AlertDialogCancel>
                    <AlertDialogAction
                        onClick={() => {
                            clearCart();
                            setOpen(false);
                        }}
                        className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                    >
                        Wyczyść listę
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};