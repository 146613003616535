// ListPage.tsx
import {useDocumentTitle} from '../../utils/useDocumentTItle';
import {useShoppingCart} from '../../contexts/ListContext';
import {Link} from 'react-router-dom';
import {AlertCircle, HomeIcon, Minus, Plus, Trash2} from 'lucide-react';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "../../components/ui/table";
import {Alert, AlertDescription, AlertTitle,} from "../../components/ui/alert";
import {Button} from "../../components/ui/button";
import {ScrollArea, ScrollBar} from "../../components/ui/scroll-area";
import {Card, CardContent, CardDescription, CardHeader, CardTitle,} from "../../components/ui/card";
import {useToast} from "../../hooks/use-toast";
import {ClearCartDialog} from "../../components/ClearCartDialog/ClearCardDialog";
import React from "react";

interface QuantityControlProps {
    quantity: number;
    onIncrease: () => void;
    onDecrease: () => void;
    min?: number;
    max?: number;
}

const QuantityControl = ({
                             quantity,
                             onIncrease,
                             onDecrease,
                             min = 1,
                             max = 99
                         }: QuantityControlProps) => (
    <div className="flex items-center space-x-2">
        <Button
            variant="outline"
            size="icon"
            onClick={onDecrease}
            disabled={quantity <= min}
            className="h-8 w-8 sm:h-8 sm:w-8 touch-manipulation"
        >
            <Minus className="h-4 w-4" />
        </Button>
        <span className="w-8 sm:w-12 text-center">{quantity}</span>
        <Button
            variant="outline"
            size="icon"
            onClick={onIncrease}
            disabled={quantity >= max}
            className="h-8 w-8 sm:h-8 sm:w-8 touch-manipulation"
        >
            <Plus className="h-4 w-4" />
        </Button>
    </div>
);

export const ListPage = () => {
    useDocumentTitle('Lista części - Katalog części KUBOTA');
    const { cartItems, removeItem, updateQuantity } = useShoppingCart();
    const { toast } = useToast();

    const handleRemoveItem = (id: number, name: string) => {
        removeItem(id);
        toast({
            title: "Usunięto z listy",
            description: `Usunięto część: ${name}`,
            variant: "destructive",
        });
    };

    if (cartItems.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center space-y-4 min-h-[400px]">
                <Alert variant="destructive" className="max-w-md">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Lista jest pusta</AlertTitle>
                    <AlertDescription>
                        Niestety w liście nie znajdują się żadne części.
                        Wróć na stronę główną i wybierz interesującą Cię maszynę.
                    </AlertDescription>
                </Alert>
                <Button asChild variant="default">
                    <Link to="/" className="flex items-center space-x-2">
                        <HomeIcon className="h-4 w-4" />
                        <span>Wróć na stronę główną</span>
                    </Link>
                </Button>
            </div>
        );
    }

    return (
        <Card className="container mx-auto py-6 mb-16 md:mb-0">
            <CardHeader>
                <CardTitle>Lista części</CardTitle>
                <CardDescription>
                    Zarządzaj swoją listą części i przejdź do zamówienia
                </CardDescription>
            </CardHeader>
            <CardContent>
                <ScrollArea className="h-auto md:h-[calc(100svh-600px)] rounded-md border">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Numer części</TableHead>
                                <TableHead>Nazwa</TableHead>
                                <TableHead>Ilość</TableHead>
                                <TableHead className="w-[100px]">Akcje</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {cartItems.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell className="font-medium">
                                        {item.partNumber}
                                    </TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>
                                        <QuantityControl
                                            quantity={item.quantity}
                                            onIncrease={() => updateQuantity(item.id, item.quantity + 1)}
                                            onDecrease={() => updateQuantity(item.id, item.quantity - 1)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => handleRemoveItem(item.id, item.name)}
                                            className="h-8 w-8 text-destructive hover:text-destructive/90"
                                        >
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <ScrollBar orientation="horizontal" />
                </ScrollArea>

                <div className="mt-6 space-y-4">
                    <p className="text-sm text-muted-foreground">
                        Wyślij listę części przez formularz po kliknięciu w przycisk "Przejdź dalej...",
                        odeślemy darmową ofertę
                    </p>
                    <div className="flex flex-col sm:flex-row justify-between items-center gap-4 sm:gap-2">
                        <ClearCartDialog
                            className="w-full sm:w-auto"
                            variant="destructive"
                        />
                        <Button
                            asChild
                            size="lg"
                            className="w-full sm:w-auto"
                        >
                            <Link to="/przeslij-liste" className="flex items-center justify-center gap-2">
                                <span className="hidden sm:inline">Przejdź dalej i</span>
                                <span>wyślij darmową listę</span>
                            </Link>
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};