// pages/NotFound/NotFound.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useDocumentTitle } from '../../utils/useDocumentTItle';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { HomeIcon, AlertTriangle } from "lucide-react";

export const NotFound = () => {
    useDocumentTitle('404 - Strona nie została znaleziona');

    return (
        <div className="container mx-auto flex items-center justify-center min-h-[calc(100vh-12rem)]">
            <Card className="max-w-md w-full">
                <CardHeader className="text-center">
                    <div className="mx-auto mb-4 bg-destructive/10 w-12 h-12 rounded-full flex items-center justify-center">
                        <AlertTriangle className="h-6 w-6 text-destructive" />
                    </div>
                    <CardTitle className="text-2xl">Strona nie została znaleziona</CardTitle>
                    <CardDescription>
                        Przepraszamy, ale strona której szukasz nie istnieje lub została przeniesiona.
                    </CardDescription>
                </CardHeader>
                <CardContent className="text-center text-muted-foreground">
                    <p>
                        Sprawdź, czy adres URL jest poprawny lub wróć na stronę główną.
                    </p>
                </CardContent>
                <CardFooter className="flex justify-center">
                    <Button asChild>
                        <Link to="/" className="flex items-center gap-2">
                            <HomeIcon className="h-4 w-4" />
                            Wróć na stronę główną
                        </Link>
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};