import React from 'react';
import {Outlet, useParams} from 'react-router-dom';
import useFetch from "../../hooks/useFetch";
import {Machine} from "../../types/Machine";
import {useMachine} from "../../hooks/useMachine";
import {useDocumentTitle} from "../../utils/useDocumentTItle";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "../../components/ui/alert";
import {
    Card,
    CardContent,
} from "../../components/ui/card";
import {AlertCircle, Loader2} from "lucide-react";
import {Skeleton} from "../../components/ui/skeleton";

const LoadingState = () => (
    <Card>
        <CardContent className="p-6">
            <div className="space-y-6">
                <div className="flex items-center space-x-4">
                    <Skeleton className="h-12 w-12 rounded-full"/>
                    <div className="space-y-2">
                        <Skeleton className="h-4 w-[250px]"/>
                        <Skeleton className="h-4 w-[200px]"/>
                    </div>
                </div>
                <Skeleton className="h-[400px] w-full"/>
            </div>
        </CardContent>
    </Card>
);

const ErrorState = ({message}: { message: string }) => (
    <Alert variant="destructive">
        <AlertCircle className="h-4 w-4"/>
        <AlertTitle>Błąd</AlertTitle>
        <AlertDescription>{message}</AlertDescription>
    </Alert>
);

export const MachinePage = () => {
    const {setMachine} = useMachine();
    const params = useParams();
    const id = params.id as string;

    const {
        data: machine,
        loading,
        error
    } = useFetch<Machine>(`${process.env.REACT_APP_API_URL}/api/v1/machines/${id}`);

    // Update document title based on machine data
    useDocumentTitle(
        machine
            ? `${machine.machineType === "MINIEXCAVATOR" ? "Minikoparka" : "Ładowarka kołowa"} ${machine.modelName} części zamienne - WOBIS`
            : "Wobis katalog części zamiennych Kubota"
    );

    // Update machine context when data is loaded
    React.useEffect(() => {
        if (machine) {
            setMachine(machine);
        }
        return () => {
            // Cleanup machine context on unmount
            setMachine(null);
        };
    }, [machine, setMachine]);

    if (loading) {
        return <LoadingState/>;
    }

    if (error) {
        return <ErrorState message="Nie udało się wczytać danych maszyny"/>;
    }

    if (!machine) {
        return <ErrorState message="Nie znaleziono maszyny"/>;
    }

    return (
        <div className="container mx-auto pb-16 md:p-3 flex flex-col">
            <Outlet/>
        </div>
    );
};
