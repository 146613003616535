import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { ListProvider } from './contexts/ListContext';
import MachineContext from './contexts/MachineContext';
import { Machine } from './types/Machine';
import { ThemeProvider } from './components/theme-provider';
import {MainLayout} from "./components/MainLayout/MainLayout";
import {HomePage} from "./components/HomePage/HomePage";
import {Contact} from "./components/ContactPage/ContactPage";
import {ListPage} from "./components/ListPage/ListPage";
import {ProceedList} from "./components/ProceedList/ProceedList";
import {MachineOverview} from "./components/MachineOverview/MachineOverview";
import {MachineComponent} from "./components/MachineComponent/MachineComponent";
import {ComponentParts} from "./components/ComponentParts/ComponentParts";
import {Toaster} from "./components/ui/toaster";
import {NotFound} from "./components/NotFound/NotFound";
import {MachinePage} from "./components/MachinePage/MachinePage";

function App() {
  const [machine, setMachine] = React.useState<Machine | null>(null);

  return (
      <ThemeProvider defaultTheme="light" storageKey="wobis-ui-theme">
        <MachineContext.Provider value={{ machine, setMachine }}>
          <ListProvider>
            <Router>
                <MainLayout>
                    <Routes>
                        {/* Home routes */}
                        <Route path="/" element={<HomePage />} />
                        <Route path="/type/:type" element={<HomePage />} />

                        {/* Contact route */}
                        <Route path="/kontakt" element={<Contact />} />

                        {/* List routes */}
                        <Route path="/lista" element={<ListPage />} />
                        <Route path="/przeslij-liste" element={<ProceedList />} />

                        {/* Machine routes */}
                        <Route path="/maszyna/:id" element={<MachinePage />}>
                            <Route index element={<MachineOverview />} />
                            <Route path="komponent/:componentId" element={<MachineComponent />} />
                            <Route
                                path="komponent/:componentId/czesci-komponentu/:figId"
                                element={<ComponentParts />}
                            />
                        </Route>

                        {/* 404 route */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </MainLayout>
                <Toaster />
            </Router>
          </ListProvider>
        </MachineContext.Provider>
      </ThemeProvider>
  );
}

export default App;
