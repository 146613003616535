// MainFooter.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';

interface MainFooterProps {
    className?: string;
}

export const MainFooter: React.FC<MainFooterProps> = ({
                                                          className
                                                      }) => {
    return (
        <footer className={cn(
            "border-t border-border bg-background px-4 py-3",
            className
        )}>
            <div className="flex flex-col sm:flex-row justify-between items-center">
                <div className="text-[10px] md:text-sm text-muted-foreground">
                    © {new Date().getFullYear()} - WOBIS Maszyny Budowlane Wojtyczek Sp. K.
                </div>
                <div className="text-[10px] md:text-xs">
                    Developed and designed with ❤️ by <Link to="https://pwcodecreations.com" className="text-primary hover:text-primary/80">PWCode Creations</Link>
                </div>
                <div className="flex space-x-4 mt-2 sm:mt-0">
                    <Link
                        to="https://minikoparki.pro"
                        className="text-[10px] md:text-sm text-primary hover:text-primary/80"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Minikoparki.pro
                    </Link>
                    <Link
                        to="https://wobis.pl"
                        className="text-[10px] md:text-sm text-primary hover:text-primary/80"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Wobis.pl
                    </Link>
                    <Link
                        to="https://sklepwobis.pl"
                        className="text-[10px] md:text-sm text-primary hover:text-primary/80"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sklepwobis.pl
                    </Link>
                </div>
            </div>
        </footer>
    );
};