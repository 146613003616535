import { ColumnDef } from "@tanstack/react-table"
import { Machine } from "../../types/Machine"
import { Link } from "react-router-dom"
import { ArrowUpDown } from "lucide-react"
import { Button } from "../../components/ui/button"

export const columns: ColumnDef<Machine>[] = [
    {
        accessorKey: "modelName",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Model
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => (
            <Link
                to={`/maszyna/${row.original.id}`}
                className="text-primary hover:underline"
            >
                {row.getValue("modelName")}
            </Link>
        ),
    },
    {
        accessorKey: "machineType",
        header: "Kategoria",
        cell: ({ row }) =>
            row.getValue("machineType") === "MINIEXCAVATOR"
                ? "Minikoparka"
                : "Ładowarka Kołowa",
    },
    {
        accessorKey: "weight",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Waga
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            )

        },
        cell: ({ row }) => `${row.getValue("weight")} ${row.original.weightUnit}`,
    },
]