// MachineOverview.tsx
import React from "react";
import {useMachine} from "../../hooks/useMachine";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import {Button} from "../../components/ui/button";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../../components/ui/tooltip";
import {
    ZoomIn,
    ZoomOut,
    Maximize,
    RotateCcw,
    Loader2, HelpCircle
} from "lucide-react";
import {Skeleton} from "../../components/ui/skeleton";
import {cn} from "../../lib/utils";

interface ZoomControlsProps {
    zoomIn: () => void;
    zoomOut: () => void;
    resetTransform: () => void;
    centerView: () => void;
    scale: number;
}

const ZoomControls: React.FC<ZoomControlsProps> = ({
                                                       zoomIn,
                                                       zoomOut,
                                                       resetTransform,
                                                       centerView,
                                                       scale
                                                   }) => (
    <TooltipProvider>
        <div className="absolute top-4 right-2 md:right-4 flex flex-col gap-2 z-30">
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={() => zoomIn()}
                        className="bg-background/80 backdrop-blur-sm"
                    >
                        <ZoomIn className="h-4 w-4"/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Przybliż</p>
                </TooltipContent>
            </Tooltip>

            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={() => zoomOut()}
                        className="bg-background/80 backdrop-blur-sm"
                    >
                        <ZoomOut className="h-4 w-4"/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Oddal</p>
                </TooltipContent>
            </Tooltip>

            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={() => centerView()}
                        className="bg-background/80 backdrop-blur-sm"
                    >
                        <Maximize className="h-4 w-4"/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Wyśrodkuj</p>
                </TooltipContent>
            </Tooltip>

            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={() => resetTransform()}
                        className="bg-background/80 backdrop-blur-sm"
                    >
                        <RotateCcw className="h-4 w-4"/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Resetuj</p>
                </TooltipContent>
            </Tooltip>

            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="flex items-center gap-2 bg-background/80 backdrop-blur-sm p-2 rounded-md">
                        <span>{scale.toFixed(1)}x</span>
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <p>Zoom</p>
                </TooltipContent>
            </Tooltip>
        </div>
    </TooltipProvider>
);

interface MachineInfoProps {
    modelName: string;
    bookCode: string;
    machineType: string;
}

const MachineInfo: React.FC<MachineInfoProps> = ({
                                                     modelName,
                                                     bookCode,
                                                     machineType,
                                                 }) => (
    <div className="space-y-4">
        <h1 className="text-3xl font-bold tracking-tight">
            {machineType === "MINIEXCAVATOR" ? "Minikoparka" : "Ładowarka Kołowa"} {modelName}
        </h1>
        <div className="flex gap-4 text-xs md:text-sm text-muted-foreground">
            <span>Model: {modelName}</span>
            <span>Kod katalogu: {bookCode}</span>
        </div>
    </div>
);

export const MachineOverview: React.FC = () => {
    const {machine} = useMachine();
    const [isImageLoading, setIsImageLoading] = React.useState(true);
    const [scale, setScale] = React.useState(1);

    if (!machine) {
        return (
            <Card>
                <CardContent className="p-6">
                    <Skeleton className="h-8 w-[300px] mb-4"/>
                    <Skeleton className="h-4 w-[200px] mb-8"/>
                    <Skeleton className="h-[400px] w-full"/>
                </CardContent>
            </Card>
        );
    }

    return (
        <div className="space-y-3 md:space-y-6">
            <MachineInfo
                modelName={machine.modelName}
                bookCode={machine.bookCode}
                machineType={machine.machineType}
            />

            <div className="text-xs py-2 flex items-center gap-2 md:hidden">
                <HelpCircle className="h-4 w-4" />
                <span>Aby wybrać podzespół maszyny kliknij menu na dolnym pasku</span>
            </div>

            <Card className="overflow-hidden relative h-[calc(100vh-150px-32px-180px)] md:h-[calc(100vh-150px-32px-120px)]">
                <CardContent className="p-0">
                    <div className="relative w-full aspect-[16/9] h-[calc(100vh-150px-32px-120px)]">
                        {isImageLoading && (
                            <div className="absolute inset-0 flex items-center justify-center bg-muted">
                                <Loader2 className="h-8 w-8 animate-spin"/>
                            </div>
                        )}
                        <TransformWrapper
                            initialScale={1}
                            maxScale={3}
                            minScale={0.85}
                            wheel={{step: 0.2}}
                            centerOnInit={true}
                            doubleClick={{mode: "reset"}}
                            onTransformed={(e) =>
                                setScale(e.state.scale)
                            }
                        >
                            {({zoomIn, zoomOut, resetTransform, centerView}) => (
                                <>
                                    <ZoomControls
                                        zoomIn={zoomIn}
                                        zoomOut={zoomOut}
                                        resetTransform={resetTransform}
                                        centerView={centerView}
                                        scale={scale}
                                    />
                                    <TransformComponent
                                        wrapperClass={cn(
                                            "!w-full !h-full justify-center transition-opacity duration-200 h-[80svh]",
                                            isImageLoading ? "opacity-0" : "opacity-100"
                                        )}
                                    >
                                        <img
                                            src={machine.imageUrl}
                                            alt={machine.modelName}
                                            className="max-w-full h-[80svh] object-contain"
                                            onLoad={() => setIsImageLoading(false)}
                                        />
                                    </TransformComponent>
                                </>
                            )}
                        </TransformWrapper>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};