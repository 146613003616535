// components/report-bug/ReportBugForm.tsx
import { useState } from 'react';
import { useToast } from "../../hooks/use-toast";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { Input } from "../../components/ui/input";
import { BugIcon } from "lucide-react";
import { Label } from "../../components/ui/label";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../components/ui/tooltip";

interface BugReport {
    email: string;
    description: string;
    url: string;
}

export const ReportBugButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { toast } = useToast();
    const [formData, setFormData] = useState<BugReport>({
        email: '',
        description: '',
        url: window.location.href,
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            // Tutaj dodaj wywołanie API do wysłania zgłoszenia
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/bug-reports`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) throw new Error('Błąd podczas wysyłania zgłoszenia');

            toast({
                title: "Zgłoszenie wysłane",
                description: "Dziękujemy za zgłoszenie błędu. Zajmiemy się nim najszybciej jak to możliwe.",
            });
            setIsOpen(false);
            setFormData({ email: '', description: '', url: window.location.href });
        } catch (error) {
            toast({
                title: "Błąd",
                description: "Nie udało się wysłać zgłoszenia. Spróbuj ponownie później.",
                variant: "destructive",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="fixed bottom-20 right-4 z-50 md:bottom-8">
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <DialogTrigger asChild>
                                <Button
                                    variant="outline"
                                    size="icon"
                                    className="rounded-full shadow-lg hover:shadow-xl transition-shadow"
                                >
                                    <BugIcon className="h-4 w-4" />
                                </Button>
                            </DialogTrigger>
                        </TooltipTrigger>
                        <TooltipContent side="left" align="center">
                            <p>Zgłoś błąd</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <DialogContent className="max-w-[80%] md:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Zgłoś błąd</DialogTitle>
                        <DialogDescription>
                            Zauważyłeś błąd w aplikacji? Pomóż nam go naprawić wypełniając poniższy formularz.
                        </DialogDescription>
                    </DialogHeader>
                    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                        <div className="space-y-2">
                            <Label htmlFor="email">Email kontaktowy</Label>
                            <Input
                                id="email"
                                type="email"
                                required
                                placeholder="twoj@email.com"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="description">Opis błędu</Label>
                            <Textarea
                                id="description"
                                required
                                placeholder="Opisz dokładnie napotkany problem..."
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                className="min-h-[100px]"
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="url">Adres URL strony</Label>
                            <Input
                                id="url"
                                type="url"
                                readOnly
                                value={formData.url}
                            />
                        </div>
                        <DialogFooter>
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => setIsOpen(false)}
                            >
                                Anuluj
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        <BugIcon className="mr-2 h-4 w-4 animate-spin" />
                                        Wysyłanie...
                                    </>
                                ) : (
                                    'Wyślij zgłoszenie'
                                )}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};