import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useShoppingCart } from '../../contexts/ListContext';
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '../../components/ui/sheet';
import { ScrollArea } from '../../components/ui/scroll-area';
import { ShoppingCart, X } from 'lucide-react';
import { Badge } from '../../components/ui/badge';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import { ClearCartDialog } from '../../components/ClearCartDialog/ClearCardDialog';
import {cn} from "../../lib/utils";

interface ShoppingCartTriggerProps {
    children?: React.ReactNode;
}

const ShoppingCartTrigger: React.FC<ShoppingCartTriggerProps> = ({ children }) => {
    const { cartQuantity } = useShoppingCart();

    return (
        <SheetTrigger asChild>
            {children || (
                <Button variant="outline" size="icon" className="relative">
                    <ShoppingCart className="h-5 w-5" />
                    {cartQuantity() > 0 && (
                        <Badge
                            variant="destructive"
                            className="absolute -top-2 -right-2 h-5 w-5 rounded-full"
                        >
                            {cartQuantity()}
                        </Badge>
                    )}
                </Button>
            )}
        </SheetTrigger>
    );
};

interface ShoppingCartSheetProps {
    children?: React.ReactNode;
    className?: string;
    contentClassName?: string;
}

export const ShoppingCartSheet: React.FC<ShoppingCartSheetProps> = ({
                                                                        children,
                                                                        className,
                                                                        contentClassName
                                                                    }) => {

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const {
        cartItems,
        cartQuantity,
        removeItem,
        updateQuantity,
    } = useShoppingCart();

    useEffect(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    }, [location.pathname]);

    return (
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
            {children ? (
                <SheetTrigger asChild>
                    {children}
                </SheetTrigger>
            ) : (
                <SheetTrigger asChild>
                    <Button
                        variant="outline"
                        size="icon"
                        className={cn("relative grid place-items-center", className)}
                    >
                        <ShoppingCart className="h-5 w-5" />
                        {cartQuantity() > 0 && (
                            <Badge
                                variant="destructive"
                                className="absolute -top-2 -right-2 min-w-5 h-5 rounded-full flex items-center justify-center px-1.5"
                            >
                                {cartQuantity()}
                            </Badge>
                        )}
                    </Button>
                </SheetTrigger>
            )}
            <SheetContent
                className={cn(
                    "w-[90%] sm:w-[540px]",
                    contentClassName
                )}
            >
                <SheetHeader>
                    <SheetTitle>Lista części ({cartQuantity()})</SheetTitle>
                </SheetHeader>

                <div className="mt-4 space-y-4">
                    {cartItems.length > 0 ? (
                        <>
                            <div className="flex justify-between items-center">
                                <ClearCartDialog />
                            </div>

                            <ScrollArea className="h-[calc(100vh-300px)] md:h-[calc(100vh-200px)]">
                                {cartItems.map((item) => (
                                    <Card key={item.id} className="p-4 mb-2">
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <p className="font-medium">{item.partNumber}</p>
                                                <p className="text-sm text-muted-foreground">{item.name}</p>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="number"
                                                    min="1"
                                                    value={item.quantity}
                                                    onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                                                    className="w-16 p-1 border rounded bg-background text-foreground"
                                                />
                                                <Button
                                                    variant="ghost"
                                                    size="icon"
                                                    onClick={() => removeItem(item.id)}
                                                >
                                                    <X className="h-4 w-4"/>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </ScrollArea>

                            <div>
                            <Button asChild>
                                    <Link to="/lista" className="flex items-center space-x-2 w-full">
                                        Przejdź do listy części
                                    </Link>
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center space-y-4 h-[calc(100vh-200px)]">
                            <ShoppingCart className="h-12 w-12 text-muted-foreground" />
                            <p className="text-lg font-medium">Twoja lista jest pusta</p>
                            <p className="text-sm text-muted-foreground text-center">
                                Dodaj części do listy, aby móc je nam wysłać
                            </p>
                            <Button asChild variant="outline">
                                <Link to="/" className="flex items-center space-x-2">
                                    Przeglądaj części
                                </Link>
                            </Button>
                        </div>
                    )}
                </div>
            </SheetContent>
        </Sheet>
    );
};