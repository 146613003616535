// ComponentParts.tsx
import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useMachine} from "../../hooks/useMachine";
import useFetch from "../../hooks/useFetch";
import {useShoppingCart} from "../../contexts/ListContext";
import {TransformComponent, TransformWrapper, useControls} from 'react-zoom-pan-pinch';
import {PartToFig} from "../../types/PartToFig";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "../../components/ui/table";
import {Card, CardContent, CardHeader, CardTitle,} from "../../components/ui/card";
import {Button} from "../../components/ui/button";
import {Checkbox} from "../../components/ui/checkbox";
import {ScrollArea, ScrollBar} from "../../components/ui/scroll-area";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,} from "../../components/ui/tooltip";
import {useToast} from "../../hooks/use-toast";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "../../components/ui/breadcrumb";
import {HelpCircle, Loader2, Maximize, RotateCcw, ShoppingCart, Trash2, ZoomIn, ZoomOut,} from "lucide-react";
import {CartItem} from "../../types/cart";
import {cn} from "../../lib/utils";
import {useDocumentTitle} from "../../utils/useDocumentTItle";

const ImageThumbnail = ({
                            url,
                            isActive,
                            onClick
                        }: {
    url: string;
    isActive: boolean;
    onClick: () => void;
}) => (
    <div
        onClick={onClick}
        className={cn(
            "relative w-20 h-20 mb-2 cursor-pointer rounded-md overflow-hidden border-2",
            isActive ? "border-primary" : "border-border hover:border-primary/50"
        )}
    >
        <img
            src={url}
            alt="Thumbnail"
            className="w-full h-full object-cover"
        />
    </div>
);

const ZoomControls = () => {
    const {zoomIn, zoomOut, resetTransform, centerView} = useControls();

    return (
        <TooltipProvider>
            <div className="absolute top-4 right-2 md:right-4 flex flex-col gap-2 z-30">
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="outline" size="icon" onClick={() => zoomIn(0.5)}>
                            <ZoomIn className="h-4 w-4"/>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>Przybliż</TooltipContent>
                </Tooltip>

                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="outline" size="icon" onClick={() => zoomOut(0.5)}>
                            <ZoomOut className="h-4 w-4"/>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>Oddal</TooltipContent>
                </Tooltip>

                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="outline" size="icon" onClick={() => centerView(1)}>
                            <Maximize className="h-4 w-4"/>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>Wyśrodkuj</TooltipContent>
                </Tooltip>

                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="outline" size="icon" onClick={() => resetTransform()}>
                            <RotateCcw className="h-4 w-4"/>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>Resetuj</TooltipContent>
                </Tooltip>
            </div>
        </TooltipProvider>
    );
};

interface SelectedPartsListProps {
    cartItems: CartItem[];
    removeItem: (id: number) => void;
}

const SelectedPartsList: React.FC<SelectedPartsListProps> = ({cartItems, removeItem}) => (
    <Card>
        <CardHeader className="px-6 py-3">
            <CardTitle className="text-lg">Wybrane części</CardTitle>
        </CardHeader>
        <CardContent>
            <ScrollArea className="h-[calc(100dvh-200px)] md:h-[393px] w-full">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>Kod</TableHead>
                        <TableHead>Nazwa</TableHead>
                        <TableHead>Ilość</TableHead>
                        <TableHead></TableHead>
                    </TableRow>
                </TableHeader>
                    <TableBody>
                        {cartItems.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell className="font-medium">{item.partNumber}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        onClick={() => removeItem(item.id)}
                                        className="h-8 w-8 text-destructive"
                                    >
                                        <Trash2 className="h-4 w-4"/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
            </Table>
                <ScrollBar orientation="horizontal"/>
            </ScrollArea>
            <div className="mt-4 flex justify-between">
                <Button variant="outline" asChild>
                    <Link to="/lista" className="flex items-center gap-2">
                        <ShoppingCart className="h-4 w-4"/>
                        Przejdź do listy
                    </Link>
                </Button>
            </div>
        </CardContent>
    </Card>
);

export const ComponentParts = () => {
    const {machine} = useMachine();
    const {toast} = useToast();
    const params = useParams();
    const figId = params.figId as string;
    const componentId = params.componentId as string;
    const [selectedPartId, setSelectedPartId] = useState<number | null>(null);
    const [transformState, setTransformState] = useState({scale: 1});
    const imageRef = useRef<HTMLImageElement>(null);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [imageSize, setImageSize] = useState({
        width: 0,
        height: 0,
        renderedWidth: 0,
        renderedHeight: 0,
    });

    const {
        data: parts,
        loading,
        error,
    } = useFetch<PartToFig[]>(
        `${process.env.REACT_APP_API_URL}/api/v1/fig-to-feature/${figId}/parts`
    );

    const {addItem, cartItems, removeItem} = useShoppingCart();

    const matchedFeature = machine?.features?.find(
        (feature) => feature.id === Number.parseInt(componentId)
    );
    const matchedFig = matchedFeature?.figToFeatures?.find(
        (feature) => feature.id === Number.parseInt(figId)
    );

    // SEO - tytuly i meta dane
    const pageTitle = matchedFig
        ? `${matchedFig.name} - ${matchedFeature?.featureName} - ${machine?.modelName} | WOBIS Części KUBOTA`
        : "Katalog części KUBOTA | WOBIS Maszyny Budowlane";

    useDocumentTitle(pageTitle);

    const metaDescription = matchedFig
        ? `Oryginalne części zamienne ${matchedFig.name} do minikoparki ${machine?.modelName}. WOBIS - Autoryzowany dealer KUBOTA. Kupuj części do maszyn budowlanych online.`
        : "Katalog części zamiennych KUBOTA. WOBIS Maszyny Budowlane - autoryzowany dealer minikoparek KUBOTA. Przeglądaj schematy i zamawiaj części.";

    const sortedIllustrations = React.useMemo(() => {
        const illustrations = [...(matchedFig?.illustrations || [])];
        return illustrations.sort((a, b) => {
            const aIsFirst = a.url.includes('_1.png');
            const bIsFirst = b.url.includes('_1.png');
            if (aIsFirst) return -1;
            if (bIsFirst) return 1;

            const aNum = a.kid;
            const bNum = b.kid;
            return aNum - bNum;
        });
    }, [matchedFig?.illustrations]);

    const getPositionsForCurrentImage = (part: PartToFig) => {
        const currentImgId = sortedIllustrations[activeImageIndex]?.kid?.toString();
        return part.positions?.filter(pos =>
            pos.imgId === null || pos.imgId === currentImgId
        ) || [];
    };

    const calculateRenderedScale = () => {
        if (imageRef.current) {
            const {naturalWidth, naturalHeight, clientWidth, clientHeight} = imageRef.current;
            setImageSize({
                width: naturalWidth,
                height: naturalHeight,
                renderedWidth: clientWidth,
                renderedHeight: clientHeight
            });
        }
    };

    useEffect(() => {
        calculateRenderedScale();

        const resizeObserver = new ResizeObserver(calculateRenderedScale);
        if (imageRef.current) {
            resizeObserver.observe(imageRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [imageRef.current]);
    const handleScaleChange = (e: any) => {
        setTransformState({scale: e.state.scale});
        calculateRenderedScale();
    };

    const handleCheckboxChange = (part: PartToFig, checked: boolean) => {
        if (checked) {
            addItem(part);
            toast({
                title: "Dodano do listy",
                description: `${part.name} został dodany do listy`,
            });
        } else {
            removeItem(part.id);
            toast({
                title: "Usunięto z listy",
                description: `${part.name} został usunięty z listy`,
                variant: "destructive",
            });
        }

    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-96">
                <Loader2 className="h-8 w-8 animate-spin"/>
            </div>
        );
    }

    if (!matchedFig || error) {
        return (
            <div className="text-center text-destructive">
                Nie znaleziono komponentu
            </div>
        );
    }

    const renderedScaleX = imageSize.renderedWidth / imageSize.width;
    const renderedScaleY = imageSize.renderedHeight / imageSize.height;

    return (
        <div>
            <Breadcrumb className="flex-none">
                <BreadcrumbList className={"text-xs"}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Strona główna</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator/>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={`/maszyna/${machine?.id}`}>
                            {machine?.modelName}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator/>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={`/maszyna/${machine?.id}/komponent/${matchedFeature?.id}`}>
                            {matchedFeature?.featureName}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator/>
                    <BreadcrumbItem>
                        <BreadcrumbLink>
                            {matchedFig?.name}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>

            <div className="text-xs py-2 flex items-center gap-2 md:hidden">
                <HelpCircle className="h-4 w-4" />
                <span>Aby wybrać podzespół maszyny kliknij menu na dolnym pasku</span>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 my-4 h-[60%]">
                <div className="lg:col-span-2">
                    <Card className="">
                        <CardHeader className="flex-none px-6 py-3">
                            <CardTitle className={"text-lg"}>{matchedFig.name}</CardTitle>
                        </CardHeader>
                        <CardContent className={"p-3 pt-0 md:p-6 md: pt-0"}>
                            <div className="flex gap-4 relative">
                                {/* Thumbnails */}
                                {sortedIllustrations.length > 1 && (
                                    <div className="flex flex-col gap-2">
                                        {sortedIllustrations.map((illustration, index) => (
                                            <ImageThumbnail
                                                key={illustration.id}
                                                url={illustration.url}
                                                isActive={index === activeImageIndex}
                                                onClick={() => {
                                                    setActiveImageIndex(index);
                                                    // Reset transformacji przy zmianie zdjęcia
                                                    setTransformState({scale: 1});
                                                }}
                                            />
                                        ))}
                                    </div>
                                )}

                                {/* Main image with zoom */}
                                <div className="h-[300px] md:h-[450px]">
                                    <TransformWrapper
                                        initialScale={0.8}
                                        maxScale={3}
                                        minScale={0.6}
                                        wheel={{step: 0.1}}
                                        centerOnInit={true}
                                        onTransformed={handleScaleChange}
                                        limitToBounds={true}
                                        smooth={true}
                                    >
                                        {() => (
                                            <div className="h-full">
                                                <ZoomControls/>
                                                <div className="h-full">
                                                    <TransformComponent
                                                        wrapperClass={"!h-full"}
                                                    >
                                                        <img
                                                            ref={imageRef}
                                                            src={sortedIllustrations[activeImageIndex]?.url}
                                                            alt={matchedFig?.name}
                                                            className="object-contain max-w-[100%] h-full block"
                                                            onLoad={() => {
                                                                if (imageRef.current) {
                                                                    const {
                                                                        naturalWidth,
                                                                        naturalHeight,
                                                                        clientWidth,
                                                                        clientHeight
                                                                    } =
                                                                        imageRef.current;
                                                                    setImageSize({
                                                                        width: naturalWidth,
                                                                        height: naturalHeight,
                                                                        renderedWidth: clientWidth,
                                                                        renderedHeight: clientHeight,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <div className="absolute inset-0">
                                                            {parts?.map((part) =>
                                                                getPositionsForCurrentImage(part).map((pos, index) => (
                                                                    <a
                                                                        href={`#part-${part.id}`}
                                                                        key={`${part.id}-${index}`}
                                                                        className={cn(
                                                                            "absolute transition-colors cursor-pointer border",  // base border
                                                                            "border-[0.5px] md:border-2",
                                                                            selectedPartId === part.id
                                                                                ? "border-primary"
                                                                                : "border-destructive hover:border-primary"
                                                                        )}
                                                                        style={{
                                                                            left: `${pos.startX * renderedScaleX}px`,
                                                                            top: `${pos.startY * renderedScaleY}px`,
                                                                            width: `${(pos.endX - pos.startX) * renderedScaleX}px`,
                                                                            height: `${(pos.endY - pos.startY) * renderedScaleY}px`,
                                                                            pointerEvents: "auto",
                                                                            transformOrigin: "center",  // ensure scaling from center
                                                                        }}
                                                                        onClick={(e) => {
                                                                            setSelectedPartId(part.id);
                                                                        }}
                                                                    />
                                                                ))
                                                            )}
                                                        </div>
                                                    </TransformComponent>
                                                </div>
                                            </div>
                                        )}
                                    </TransformWrapper>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div className="lg:col-span-1">
                    <SelectedPartsList cartItems={cartItems} removeItem={removeItem}/>
                </div>
            </div>

            <Card className="h-auto md:h-[calc(100svh-400px)] mb-[50px] md:mb-0">
                <CardHeader className="px-6 py-3">
                    <CardTitle className="text-lg">Lista części</CardTitle>
                </CardHeader>
                <CardContent>
                    <ScrollArea className="h-auto md:h-[calc(100svh-480px)]">
                    <Table>
                        <TableHeader className="sticky top-0 bg-background z-10">
                            <TableRow>
                                <TableHead>Numer</TableHead>
                                <TableHead>Kod Części</TableHead>
                                <TableHead>Nazwa Części</TableHead>
                                <TableHead>Numer seryjny</TableHead>
                                <TableHead>Uwaga</TableHead>
                                <TableHead>Ilość</TableHead>
                                <TableHead>Waga</TableHead>
                                <TableHead>Dodaj</TableHead>
                            </TableRow>
                        </TableHeader>
                            <TableBody>
                                {parts?.sort((a, b) => a.referenceNumber.localeCompare(b.referenceNumber)).map((part) => (
                                    <TableRow
                                        key={part.id}
                                        id={`part-${part.id}`}
                                        className={
                                            selectedPartId === part.id ? "bg-primary/10" : undefined
                                        }
                                    >
                                        <TableCell>{part.referenceNumber}</TableCell>
                                        <TableCell>{part.wobisPartNumber}</TableCell>
                                        <TableCell>{part.name}</TableCell>
                                        <TableCell>{part.serialNumber}</TableCell>
                                        <TableCell>{part.note}</TableCell>
                                        <TableCell>{part.quantity}</TableCell>
                                        <TableCell>
                                            {part.weight} {part.weightUnit}
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={cartItems.some((item) => item.id === part.id)}
                                                onCheckedChange={(checked) =>
                                                    handleCheckboxChange(part, checked as boolean)
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                    </Table>
                        <ScrollBar orientation="horizontal" />
                    </ScrollArea>
                </CardContent>
            </Card>
        </div>
    );
};